import Layout from "../layouts/root";

const routes = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("../pages/home")
      },
      {
        path: "results",
        name: "Search Results",
        component: () => import("../pages/results"),
        props: {
          postcode: null
        }
      },
      {
        path: "contact",
        name: "Contact",
        component: () => import("../pages/contact")
      },
      {
        path: "why-grass-fed",
        name: "Why grass-fed?",
        component: () => import("../pages/why-grass-fed")
      },
      {
        path: "about",
        name: "About",
        component: () => import("../pages/about")
      },
      {
        path: ":name",
        name: "Shop",
        props: true,
        component: () => import("../pages/shop")
      }
    ]
  },
  {
    path: "*",
    component: Layout,
    children: [
      {
        path: "",
        name: "404",
        component: () => import("../pages/404")
      }
    ]
  }
];

export default routes;
