<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import BadgePFLA from "../src/assets/images/pfla-logo.png";

(function(y, a, f, i) {
  var head = a.getElementsByTagName("head")[0];
  var script = a.createElement("script");

  y["_yafi-widget"] = { siteId: i, url: f };

  script.async = 1;
  script.src = f + "widget/" + i;

  head.appendChild(script);
})(window, document, "https://widget.yafi.pro/", "5e3c3f63a3a8f5002b7809a0");

export default {
  name: "app",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Eat the best grass-fed meat | Where To Meat",
    // all titles will be injected into this template
    titleTemplate: "%s | Where To Meat",
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "X-UA-Compatible", content: "IE=edge" },
      {
        name: "keywords",
        content:
          "meat,grass-fed,grassfed,grass fed,beef,pasture,pasture fed,london,farm,butchers,grass fed beef,pasture fed beef",
        vmid: "keywords"
      },
      {
        name: "description",
        content:
          "Where To Meat will help you find butchers, farms, and other shops across the UK that sell the best quality 100% grass-fed beef and lamb.",
        vmid: "description"
      },
      {
        property: "og:title",
        content: "Eat the best grass-fed meat",
        // following template options are identical
        // template: '%s - My page',
        template: chunk => `${chunk} | Where To Meat`,
        vmid: "og:title"
      },
      {
        property: "og:description",
        content:
          "Where To Meat will help you find butchers, farms, and other shops across the UK that sell the best quality 100% grass-fed beef and lamb.",
        // following template options are identical
        // template: '%s - My page',
        template: chunk => `${chunk}`,
        vmid: "og:description"
      },
      {
        property: "og:image",
        content: "https://wheretomeat.com/android-chrome-512x512.png",
        // following template options are identical
        // template: '%s - My page',
        template: chunk => `${chunk}`,
        vmid: "og:image"
      },
      { name: "msapplication-TileColor", content: "#bf2a2a" },
      { name: "msapplication-config", content: "/browserconfig.xml" },
      { name: "apple-mobile-web-app-title", content: "Where To Meat" },
      { name: "application-name", content: "Where To Meat" },
      { name: "msapplication-TileColor", content: "#ffffff" },
      { name: "theme-color", content: "#bf2a2a" }
    ],
    link: [
      { rel: "stylesheet", href: "/css/raster2.css" },
      { rel: "stylesheet", href: "https://use.typekit.net/oos8yha.css" },
      { rel: "favicon", href: "/favicon.ico" },
      {
        rel: "apple-touch-icon",
        sizes: "180x180",
        href: "/apple-touch-icon.png"
      },
      {
        rel: "icon",
        type: "image/png",
        sizes: "32x32",
        href: "/favicon-32x32.png"
      },
      {
        rel: "icon",
        type: "image/png",
        sizes: "16x16",
        href: "/favicon-16x16.png"
      },
      { rel: "manifest", href: "/site.webmanifest" },
    ],
    script: [
      {
        src: "https://kit.fontawesome.com/9cfbc61343.js",
        crossorigin: "anonymous"
      }
    ]
  },
  computed: {
    badgePFLA() {
      return BadgePFLA;
    }
  }
};
</script>

<style lang="scss">
@import "@/global-styles/variables.scss";
@import "@/global-styles/main.scss";
@import "@/global-styles/typography.scss";
@import "@/global-styles/colours.scss";
@import "@/global-styles/spacing.scss";
@import "@/global-styles/layout.scss";
@import "@/global-styles/styles.scss";
@import "@/global-styles/buttons.scss";
@import "@/global-styles/forms.scss";
</style>
