<template>
  <footer>
    <cookie-law theme="blood-orange"></cookie-law>
  </footer>
</template>

<script>
import CookieLaw from "vue-cookie-law";
export default {
  components: { CookieLaw }
};
</script>
