<template>
  <div>
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";

export default {
  name: "layout",
  components: {
    Header,
    Footer
  }
};
</script>

<style></style>
