import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import VueGtag from "vue-gtag";
import routes from "../router/router";
import config from "./config.js";
import VueMeta from "vue-meta";
import vueSmoothScroll from "vue2-smooth-scroll";
import "lazysizes";
import "isomorphic-fetch"; // as fetch isn't supported by IE11

Vue.use(vueSmoothScroll);

Vue.use(VueMeta);

Vue.config.ignoredElements = ["r-grid", "r-cell"];

Vue.config.productionTip = false;

var SocialSharing = require("vue-social-sharing");
Vue.use(SocialSharing);

Vue.use(VueGtag, {
  config: {
    id: config.gtm.trackingId,
    params: {
      send_page_view: true
    }
  }
});

Vue.use(VueRouter);
// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

new Vue({
  router,
  el: "#app",
  render: h => h(App)
}).$mount("#app");
