<template>
  <ul class="menu-links">
    <li>
      <a href="" @click.prevent="goToLocation()">
        Locations <i class="fad fa-arrow-right"></i>
      </a>
    </li>
    <li>
      <a href="" @click.prevent="goToGrassFed()">
        Why Grass-Fed? <i class="fad fa-arrow-right"></i>
      </a>
    </li>
    <li>
      <a href="" @click.prevent="goToAbout()">
        About Us<i class="fad fa-arrow-right"></i>
      </a>
    </li>
    <li>
      <a href="" @click.prevent="goToContact()">
        Contact <i class="fad fa-arrow-right"></i>
      </a>
    </li>
    <li>
      <a
        class="link-red"
        href="#newsletter"
        click="trackEvent('click_subscribe', 'newsletter', 'header')"
        v-smooth-scroll="{
          duration: 1000,
          offset: -50,
          updateHistory: false
        }"
      >
        <i class="fad fa-newspaper"></i> Newsletter
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "MenuLinks",
  methods: {
    trackEvent(action, category, label) {
      // label is optional
      if (label) {
        this.$gtag.event(action, {
          event_category: category,
          event_label: label
        });
      } else {
        this.$gtag.event(action, {
          event_category: category
        });
      }
    },
    closeMenu() {
      this.$emit("closeMenu");
    },
    goToLocation() {
      this.closeMenu();
      this.$router.push("/results").catch(err => {
        console.log(err);
      });
    },
    goToGrassFed() {
      this.closeMenu();
      this.$router.push("/why-grass-fed").catch(err => {
        console.log(err);
      });
    },
    goToAbout() {
      this.closeMenu();
      this.$router.push("/about").catch(err => {
        console.log(err);
      });
    },
    goToContact() {
      this.closeMenu();
      this.$router.push("/contact").catch(err => {
        console.log(err);
      });
    }
  }
};
</script>

<style lang="scss">
@import "@/global-styles/variables.scss";

.menu-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0;

    &:last-of-type {
      a,
      div {
        color: $primary;
      }

      svg {
        display: inline;
        margin-right: $spacing-1;
      }
    }
  }

  a,
  div {
    color: inherit;
    display: inline-block;
    padding: $spacing-4 $spacing-3;
    text-decoration: none;
    cursor: pointer;

    &:visited {
      color: $black;
    }

    svg {
      display: none;
    }

    &:focus,
    &:hover {
      color: $primary;
      outline: none;
    }

    &:active {
      background-color: $grey-5;
      transition: background-color $transition;
    }
  }
}

@media screen and (max-width: $small-bp - 1px) {
  .menu-links {
    border-top: 1px solid $grey-4;
    flex-flow: wrap;
    padding: 0.5rem 0;
    background: $white;

    li {
      width: 100%;
    }

    a,
    div {
      font-weight: 600;
      display: block;
      font-size: $font-size-4;
      padding: $font-size-4;

      svg {
        display: block;
        float: right;
      }
    }
  }
}
</style>
