export const config = {
    airtable: {
        baseUrl: `${process.env.VUE_APP_AIRTABLE_ENDPOINT}/${process.env.VUE_APP_AIRTABLE_BASE}/${process.env.VUE_APP_AIRTABLE_TABLE}`,
        token: process.env.VUE_APP_AIRTABLE_TOKEN,
    },
    storage: {
        endpoint: process.env.VUE_APP_WTM_STORAGE_ENDPOINT
    },
    mapsApi: {
        geocode: {
            endpoint: 'https://maps.googleapis.com/maps/api/geocode/json?address='
        },
        key: process.env.VUE_APP_THIRD_PARTY_MAPS_API_KEY
    },
    gtm: {
        trackingId: process.env.VUE_APP_THIRD_PARTY_GA_TRACKING_ID
    },
    localStorage: {
        keyTTLSuffix: "_createdAt",
        ttl: process.env.VUE_APP_LOCAL_STORAGE_TTL
    }
}

export default config